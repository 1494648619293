import { createApp } from 'vue'
import App from './App.vue'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import {createPinia} from "pinia";
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faPenToSquare, faUser, faCircleMinus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'

import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
/* add icons to the library */
library.add(faPenToSquare, faUser, faCircleMinus, faArrowsRotate)

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(router)
    .use(pinia)
    .use(VueSidebarMenu)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
