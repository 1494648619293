<template>
  <div class="hello">
    <div class="meeting-types-wrapper">
      <q-table
          title="Типы встреч"
          :rows="meetingTypes"
          :columns="columns"
          :pagination="pagination"
          row-key="meetingType"
          binary-state-sort
      >
      </q-table>
      <div class="add-meeting-type">
        <q-input outlined v-model="newMeetingType" label="Название встречи" />
        <q-btn @click="addMeetingType" color="primary" label="Добавить новый тип встречи" />
      </div>
    </div>
  </div>
</template>

<script>
import grpc from "@/services/grpc";

export default {
  name: 'MeetingTypes',
  created() {
    this.getMeetingTypes();
  },
  data() {
    const columns = [
      {
        name: 'name',
        required: true,
        label: 'Название встречи',
        align: 'left',
        field: row => row.getName(),
        format: val => `${val}`,
        sortable: true
      },
    ];
    const pagination = {
      sortBy: 'asc',
      descending: false,
      rowsPerPage: 0
    };
    return {
      grpcClient: new grpc(),
      newMeetingType: "",
      meetingTypes: [],
      meetingTypeOptions: [],
      columns: columns,
      pagination: pagination,
    };
  },
  methods: {
    async addMeetingType() {
      if (!this.newMeetingType) {
        return;
      }
      await this.grpcClient.createMeetingType(this.newMeetingType);
      await this.getMeetingTypes()
      this.newMeetingType = "";
    },
    async getMeetingTypes() {
      this.meetingTypes = await this.grpcClient.getMeetingTypes()
      for (let meetingType of this.meetingTypes) {
        this.meetingTypeOptions.push({
          label: meetingType.getName(),
          value: meetingType.getId()
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.meeting-types-wrapper {
  max-width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-meeting-type {
  display: inline-block;
  padding: 20px 0;
  .q-field {
    margin-bottom: 20px;
  }
}
</style>
