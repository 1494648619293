<template>
  <div class="hello">
    <div v-if="serviceIsReady()">
      <div class="teams">
        <div v-if="!singleTeamMode" class="teams-table-block">
          <q-table
              title="Команды"
              :rows="teams"
              :columns="teamsTableColumns"
              :pagination="teamTablePagination"
              :loading="teams.length === 0"
              row-key="name"
              binary-state-sort
          >
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td v-ripple key="name" :props="props">
                  <span class="clickable">{{ props.row.name }}</span>
                  <q-popup-edit v-model="props.row.name" title="Изменить имя" auto-save v-slot="scope">
                    <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set(); changeTeamField(props.row.id, 'name' ,props.row.name)" />
                  </q-popup-edit>
                </q-td>
                <q-td v-ripple key="messenger_group_id" :props="props">
                  <span class="clickable">{{ props.row.messengerGroupId }}</span>
                  <q-popup-edit v-model="props.row.messengerGroupId" title="Изменить ID группы в месседжере" auto-save v-slot="scope">
                    <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set(); changeTeamField(props.row.id, 'messenger_group_id' ,props.row.messengerGroupId)" />
                  </q-popup-edit>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
        <div class="team" v-for="team in teams" :key="team.getId()">
          <div class="team-inner-block">
            <div>
              <div class="q-pa-md">
                <q-table
                    :title="team.getName()"
                    :rows="team.getMembers()"
                    :columns="teamTableColumns"
                    :pagination="teamTablePagination"
                    row-key="name"
                    binary-state-sort
                >
                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td v-ripple key="name" :props="props">
                        <span class="clickable">{{ props.row.name }}</span>
                        <q-popup-edit v-model="props.row.name" title="Изменить имя" auto-save v-slot="scope">
                          <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set(); changePresenterField(props.row.getId(), 'name' ,props.row.name)" />
                        </q-popup-edit>
                      </q-td>
                      <q-td v-ripple key="messenger" :props="props">
                        <div class="clickable">
                          <span v-if="props.row.getMessenger()">@</span>{{ props.row.messenger }}
                        </div>
                        <q-popup-edit v-model="props.row.messenger" title="Изменить ник в мессенджере" auto-save v-slot="scope">
                          <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set(); changePresenterField(props.row.getId(), 'messenger_nick_name' ,props.row.messenger)" />
                        </q-popup-edit>
                      </q-td>
                      <q-td key="meetingTypes" :props="props">
                        <Multiselect
                            v-model="meetingTypesForMembers[props.row.getId()]"
                            @close="updateMeetingTypesForMember(props.row.getId())"
                            mode="tags"
                            placeholder="Select meeting types"
                            track-by="label"
                            label="label"
                            :searchable="true"
                            :close-on-select="false"
                            :options="meetingTypeOptions"
                        >
                          <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div
                                class="multiselect-tag is-user"
                                :class="{
            'is-disabled': disabled
          }"
                            >
                              {{ option.label }}
                              <span
                                  v-if="!disabled"
                                  class="multiselect-tag-remove"
                                  @click="handleTagRemove(option, $event)"
                              >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
                            </div>
                          </template>
                        </Multiselect>
                      </q-td>
                      <q-td key="isOnVacation" :props="props">
                        <q-toggle v-model="props.row.isOnVacation"
                                  @update:model-value="toggleOnVacationState(props.row.getId())"
                        />
                      </q-td>
                      <q-td class="remove-presenter-icon" key="removePresenter" :props="props">
                        <q-btn @click="removePresenter(props.row.getId())">
                          <font-awesome-icon icon="fa-solid fa-circle-minus" />
                        </q-btn>
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>
              </div>
            </div>
            <div class="new-presenter-form">
              <q-input outlined v-model="newPresenters[team.getId()]" label="Имя" />
              <q-input outlined v-model="newPresenterNickNames[team.getId()]" label="Ник в мессенджере" />
              <q-btn @click="addPresenter(newPresenters[team.getId()], newPresenterNickNames[team.getId()], team.getId())" color="primary" label="Добавить ведущего" />
            </div>
          </div>
          <div class="team-rotation-block">
            <q-table
                :title="currentSprintName"
                :rows="getRotationData(team)"
                :columns="rotationTableColumns"
                :pagination="teamTablePagination"
                row-key="meetingType"
                binary-state-sort
            >
              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td v-ripple key="meetingType" :props="props">
                    <span>{{ props.row.meetingType }}</span>
                  </q-td>
                  <q-td v-ripple key="presenter" :props="props">
                    <span>{{ props.row.presenter }}</span>
                  </q-td>
                  <q-td class="remove-presenter-icon">
                    <q-btn @click="rotatePresenterForTeamAndMeetingType(team, props.row.meetingTypeId)">
                      <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
                    </q-btn>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
            <q-btn class="rotation-button" @click="rotatePresentersForTeam(team)" color="primary" label="Ротировать всех ведущих"  />
          </div>
        </div>
      </div>
    </div>
    <q-spinner-cube
        v-if="!serviceIsReady()"
        color="green"
        size="5.5em"
    />
  </div>
</template>

<script>
import grpc from "@/services/grpc";
import Multiselect from '@vueform/multiselect'
import Member from "@/models/Member";
import Team from "@/models/Team";
import RotationTeamData from "@/models/RotationTeamData";
import MeetingTypeToPresenter from "@/models/MeetingTypeToPresenter";

const teamsTableColumns = [
  {
    name: 'name',
    required: true,
    label: 'Имя',
    align: 'left',
    field: row => row.getName(),
    format: val => `${val}`,
    sortable: true
  },
  { name: 'messenger_group_id', align: 'center', label: '№ группы в мессенджере', field: 'messenger_group_id'},
];
const teamTableColumns = [
  {
    name: 'name',
    required: true,
    label: 'Имя',
    align: 'left',
    field: row => row.getName(),
    format: val => `${val}`,
    sortable: true
  },
  { name: 'messenger', align: 'center', label: 'Ник в мессенджере', field: row => row.getMessenger(), sortable: true },
  { name: 'meetingTypes', label: 'Типы встреч', field: 'meetingTypes' },
  { name: 'isOnVacation', label: 'В отпуске', field: 'isOn_vacation' },
  { name: 'removePresenter', label: 'Удалить ведущего', field: 'id' },
];
const rotationTableColumns = [
  { name: 'meetingType', label: 'Типы встречи', field: 'meetingType' },
  { name: 'presenter', label: 'Ведущий', field: 'presenter' },
  { name: 'rotate', label: 'Ротировать' },
]

const teamTablePagination = {
  sortBy: 'asc',
  descending: false,
  rowsPerPage: 0
};

export default {
  name: 'TeamsBlock',
  components: {
    Multiselect,
  },
  created() {
    this.initSingleMode(this.$route.params.id);
    this.initApp();
  },
  watch: {
    $route(to) {
      this.initSingleMode(to.params.id);
    }
  },
  data() {
    return {
      singleTeamId: null,
      singleTeamMode: false,
      grpcClient: new grpc(),
      newPresenters: [],
      newPresenterNickNames: [],
      teams: [],
      meetingTypes: [],
      meetingTypesForMembers: [],
      meetingTypeOptions: [],
      teamsTableColumns: teamsTableColumns,
      teamTableColumns: teamTableColumns,
      teamTablePagination: teamTablePagination,
      rotationTableColumns: rotationTableColumns,
      rotationData: [],
      currentSprintName: '',
    };
  },
  methods: {
    initApp() {
      this.getMeetingTypes();
      this.getTeams();
      this.getCurrentSprintName();
    },
    async getTeams() {
      const teams = await this.grpcClient.getTeams();
      this.teams = [];
      this.meetingTypesForMembers = [];
      for (let team of teams) {
        const teamToAdd = new Team(team.getId(), team.getName(), team.getMessengerGroupId());
        const members = await this.grpcClient.getPresenters(team.getId());
        for (let member of members) {
          const memberToAdd = new Member(
              member.getId(),
              member.getName(),
              member.getMessengerNickName(),
              member.getMeetingTypesList(),
              member.getIsOnVacation()
          );
          teamToAdd.addMember(memberToAdd);
          for (let meetingType of member.getMeetingTypesList()) {
            if (!this.meetingTypesForMembers[member.getId()]) {
              this.meetingTypesForMembers[member.getId()] = [];
            }
            this.meetingTypesForMembers[memberToAdd.getId()].push(meetingType.getId());
          }
        }
        if (this.showTeam(teamToAdd)) {
          this.teams.push(teamToAdd);
          await this.setRotationData(teamToAdd);
        }
      }
    },
    async getMeetingTypes() {
      this.meetingTypes = await this.grpcClient.getMeetingTypes()
      for (let meetingType of this.meetingTypes) {
        this.meetingTypeOptions.push({
          label: meetingType.getName(),
          value: meetingType.getId()
        });
      }
    },
    async addPresenter(name, nickname, teamId) {
      if (!name) {
        return;
      }
      const presenter = await this.grpcClient.createPresenter(name, nickname, teamId);
      if (presenter.getId() > 0) {
        this.addNewPresenterToTeam(teamId, presenter, nickname);
      }
      delete (this.newPresenters[teamId]);
      delete (this.newPresenterNickNames[teamId]);
    },
    async removePresenter(memberId) {
      const status = await this.grpcClient.deletePresenter(memberId);
      if (status) {
        this.removeMemberFromTeam(memberId);
      }
    },
    removeMemberFromTeam(memberId) {
      for (let team of this.teams) {
        for (let member of team.getMembers()) {
          if (member.getId() === memberId) {
            team.members.splice(team.members.indexOf(member), 1);
          }
        }
      }
    },
    updateMeetingTypesForMember(memberId) {
      let meetingTypes = this.meetingTypesForMembers[memberId];
      this.grpcClient.updateMeetingTypesForMember(memberId, meetingTypes);
    },
    getCurrentSprintName() {
      this.grpcClient.getCurrentSprintName().then(name => {
        this.currentSprintName = name;
      });
    },
    addNewPresenterToTeam(teamId, presenter, nickname) {
      const member = new Member(
          presenter.getId(),
          presenter.getName(),
          nickname,
          this.meetingTypesForMembers[teamId]
      );
      this.teams.find(team => team.getId() === teamId).addMember(member);
    },
    changePresenterField(presenterId, fieldName, value) {
      this.grpcClient.changePresenterField(presenterId, fieldName, value);
    },
    changeTeamField(teamId, fieldName, value) {
      this.grpcClient.changeTeamField(teamId, fieldName, value);
    },
    getRotationData(team) {
      const data = [];
      if (this.rotationData[team.getId()]) {
        for (let elem of this.rotationData[team.getId()].getPresentersToMeetingTypesData()) {
          data.push(elem);
        }
      }
      return data;
    },
    async setRotationData(teamToAdd) {
      let rotationData = new RotationTeamData(this.currentSprintName);
      await this.grpcClient.getRotationDataForTeam(teamToAdd.getId()).then(rotations => {
        for (let rotation of rotations) {
          console.log(rotation);
          const meetingTypeToPresenter = new MeetingTypeToPresenter(rotation.getMeetingType(), rotation.getPresenter());
          rotationData.addPresentersToMeetingTypesData(meetingTypeToPresenter);
        }
      });
      this.rotationData[teamToAdd.getId()] = rotationData;
    },
    async rotatePresentersForTeam(team) {
      let rotationData = new RotationTeamData(this.currentSprintName);
      const rotations = await this.grpcClient.rotateTeamPresenters(team.getId());
      for (let rotation of rotations) {
        const meetingTypeToPresenter = new MeetingTypeToPresenter(rotation.getMeetingType(), rotation.getPresenter());
        rotationData.addPresentersToMeetingTypesData(meetingTypeToPresenter);
      }
      this.rotationData[team.getId()] = rotationData;
    },
    async rotatePresenterForTeamAndMeetingType(team, meetingTypeId) {
      console.log(team, meetingTypeId);
      let rotationData = new RotationTeamData(this.currentSprintName);
      const rotations = await this.grpcClient.rotateTeamPresentersForMeetingType(team.getId(), meetingTypeId);
      for (let rotation of rotations) {
        const meetingTypeToPresenter = new MeetingTypeToPresenter(rotation.getMeetingType(), rotation.getPresenter());
        rotationData.addPresentersToMeetingTypesData(meetingTypeToPresenter);
      }
      this.rotationData[team.getId()] = rotationData;
    },
    initSingleMode(teamId) {
      this.singleTeamId = parseInt(teamId);
      this.singleTeamMode = this.singleTeamId !== undefined && teamId !== null && !isNaN(teamId);
    },
    showTeam(team) {
      if (!this.singleTeamMode) {
        return true;
      }
      return team.getId() === this.singleTeamId;
    },
    serviceIsReady() {
      return this.meetingTypeOptions.length > 0 && this.teams.length > 0;
    },
    async toggleOnVacationState(presenterId) {
      return await this.grpcClient.toggleOnVacation(presenterId)
    }
  },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.q-table__middle.scroll {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.team {
  border-bottom: 1px solid silver;
  display: inline-block;
  padding: 30px;
}

.member {
  font-weight: bold;
}

.team-title {
  margin-top: 0;
}
.teams {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.team {
  display: flex;
  align-items: baseline;
}
.team-inner-block {
  padding-right: 20px;
}
.new-presenter-form {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  label {
    min-width: 200px;
  }
}
.q-table__top {
  justify-content: center;
}
.remove-presenter-icon {
  cursor: pointer;
}
.rotation-button {
  margin-top: 26px;
}
.clickable {
  cursor: pointer;
}
</style>
