<template>
  <div class="home">
    <AddTeam/>
  </div>
</template>

<script>

import AddTeam from "@/components/AddTeam";

export default {
  name: 'AddTeamView',
  components: {
    AddTeam
  }
}
</script>
