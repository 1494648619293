<template>
  <div class="home">
    <MeetingTypes/>
  </div>
</template>

<script>

import MeetingTypes from "@/components/MeetingTypes";

export default {
  name: 'MeetingTypesView',
  components: {
    MeetingTypes

  }
}
</script>
