<template>
  <div class="hello">
    <div>
      <h3>Добавление команды</h3>
      <div class="addTeam">
        <div class="addTeam-element-wrapper">
          <q-input outlined v-model="newTeam" label="Название команды" />
        </div>
        <div class="addTeam-element-wrapper">
          <q-btn @click="addTeam" color="primary" label="Добавить команду" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import grpc from "@/services/grpc";

export default {
  name: 'AddTeam',
  data() {
    return {
      grpcClient: new grpc(),
      newTeam: "",
    };
  },
  methods: {
    addTeam() {
      if (!this.newTeam) {
        return;
      }
      this.grpcClient.createTeam(this.newTeam);
      this.newTeam = "";
      this.goBack();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
}
</script>

<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}

.addTeam {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .addTeam-element-wrapper {

  }
  & > * {
    margin: 0.5rem 0;
  }
  label {
    width: 200px;
  }
}
</style>
