export default class {
    id
    name
    messenger
    meetingTypes;
    isOnVacation;

    constructor(id, name, messenger, meetingTypes, isOnVacation) {
        this.id = id;
        this.name = name;
        this.messenger = messenger;
        this.meetingTypes = meetingTypes;
        this.isOnVacation = isOnVacation;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getMessenger() {
        return this.messenger;
    }

    getMeetingTypes() {
        return this.meetingTypes;
    }

    getIsOnVacation() {
        return this.isOnVacation;
    }
}