import {createRouter, createWebHistory} from 'vue-router'
import MainPageView from "@/views/MainPageView";
import AddTeamView from "@/views/AddTeamView";
import MeetingTypesView from "@/views/MeetingTypesView";
import TeamsView from "@/views/TeamsView";

const routes = [
    {
        path: '/',
        name: 'Main page',
        component: MainPageView
    },
    {
        path: '/teams',
        name: 'Teams',
        component: TeamsView
    },
    {
        path: '/teams/add',
        name: '+ add team',
        component: AddTeamView
    },
    {
        path: '/teams/:id',
        name: 'UDT',
        component: TeamsView
    },
    {
        path: '/meeting-types',
        name: 'Meeting Types',
        component: MeetingTypesView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
