<template>
  <div class="home">
    <h3>Выберите нужный раздел в меню.</h3>
  </div>
</template>

<script>
import grpc from "@/services/grpc";

export default {
  name: 'MainPageComponent',
  data() {
    return {
      grpcClient: new grpc(),
    };
  },
}
</script>