export default class {
    sprintName
    presentersToMeetingTypesData;

    constructor(sprintName) {
        this.sprintName = sprintName;
        this.presentersToMeetingTypesData = [];
    }

    getSprintName() {
        return this.sprintName;
    }

    getPresentersToMeetingTypesData() {
        return this.presentersToMeetingTypesData;
    }

    addPresentersToMeetingTypesData(meetingTypeToPresenter) {
        this.presentersToMeetingTypesData.push(meetingTypeToPresenter);
    }
}