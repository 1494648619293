export default class {
    constructor(id, name, messengerGroupId) {
        this.id = id;
        this.name = name;
        this.messengerGroupId = messengerGroupId;
        this.members = [];
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getMembers() {
        return this.members;
    }

    addMember(member) {
        this.members.push(member);
    }

    getMessengerGroupId() {
        return this.messengerGroupId;
    }
}