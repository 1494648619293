<template>
  <div class="home">
    <TeamsBlock/>
  </div>
</template>

<script>
import TeamsBlock from "@/components/TeamsBlock";

export default {
  name: 'TeamsView',
  components: {
    TeamsBlock
  }
}
</script>
