export default class {
    meetingType;
    presenter;

    constructor(meetingType, presenter) {
        this.meetingType = meetingType;
        this.presenter = presenter;
    }

    getMeetingType() {
        return this.meetingType;
    }

    getPresenter() {
        return this.presenter;
    }
}