<template>
  <sidebar-menu
      :menu="menu"
  />
  <div class="content">
    <h1 class="main-title">Сервис ротации в SCRUM-процессах</h1>
    <router-view/>
  </div>
</template>

<style src="@fortawesome/fontawesome-free/css/all.css"></style>
<script>
import grpc from "@/services/grpc";

class Team {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }
}


export default {
  name: 'App',
  created() {
    this.getTeams()
  },
  data() {
    return {
      grpcClient: new grpc(),
      menu: this.getMenu(),
    };
  },
  methods: {
    async getTeams() {
      const teams = await this.grpcClient.getTeams();
      const teamsResponse = [];
      for (let team of teams) {
        const teamToAdd = new Team(team.getId(), team.getName());
        teamsResponse.push(teamToAdd);
      }
      this.addTeamsToLocalStorage(teamsResponse);
    },
    addTeamsToLocalStorage(teams) {
      localStorage.setItem('teams', JSON.stringify(teams));
    },
    fillTeamsFromLocalStorage(teams) {
      const jsonData = localStorage.getItem('teams');
      if (jsonData != null && jsonData.length > 0) {
        const sidebarTeams = JSON.parse(jsonData);
        for (let team of sidebarTeams) {
          const teamMenuElement = {
            href: '/teams/' + team.id,
            title: team.name,
            icon: 'fa-solid fa-user'
          };
          teams.push(teamMenuElement);
        }
      }
    },
    getMenu() {
      const teams = [];
      const addTeamElement = {
        href: '/teams/add',
        title: '+ добавить команду',
        icon: 'fa-solid fa-user-plus'
      };
      this.fillTeamsFromLocalStorage(teams);
      teams.push(addTeamElement);
      return [
        {
          header: 'Меню',
          hiddenOnCollapse: true
        },
        {
          href: '/',
          title: 'Главная',
          icon: 'fa-solid fa-house'
        },
        {
          href: '/teams',
          title: 'Команды',
          icon: 'fa-solid fa-people-group',
          child: teams
        },
        {
          href: '/meeting-types',
          title: 'Типы встреч',
          icon: 'fa-solid fa-handshake',
        }
      ]
    }
  }
}

</script>

<style lang="scss">
body{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: row;
}

.v-sidebar-menu.vsm_expanded + .content {
  padding-left: 290px;
}

.v-sidebar-menu.vsm_collapsed + .content {
  padding-left: 65px;
}

.content {
  flex: 1;
}

.main-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: $green-8;
  margin-top: 0;
  color: white;
  text-shadow: 1px 1px rgb(0 0 0 / 38%);
}
</style>
