<template>
  <div class="home">
    <MainPageComponent />
  </div>
</template>

<script>
import MainPageComponent from "@/components/MainPage";

export default {
  name: 'MainPageView',
  components: {
    MainPageComponent
  }
}
</script>