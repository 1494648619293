import {PresenterRotatorServicePromiseClient} from '@/proto/presenter-rotator_grpc_web_pb'
import {
    AddPresenterRequest,
    Name,
    Nothing,
    RemovePresenterRequest,
    TeamIDRequest,
    TeamIDAndMeetingTypeRequest,
    UpdateMeetingTypesRequest,
    ChangePresenterFieldRequest,
    ChangeTeamFieldRequest,
    ToggleOnVacationStateRequest,
} from '../proto/presenter-rotator_pb'

export default class {
    client

    constructor() {
        this.client = new PresenterRotatorServicePromiseClient(this.getBackendAddress(), null, null)
    }

    getBackendAddress() {
        let url = process.env.VUE_APP_BACKEND_ADDRESS;
        const port = process.env.VUE_APP_BACKEND_PORT || '80';
        const backedEndpoint = process.env.BACKEND_ENDPOINT;
        if (backedEndpoint !== undefined) {
            url = backedEndpoint
        }
        if (port !== '80') {
            url = url + ':' + port
        }
        return url;
    }

    async createTeam(teamName) {
        const req = new Name()
        req.setName(teamName)
        try {
            const res = await this.client.createTeam(req, {})
            return res.getName()
        } catch (err) {
            console.error(err.message)
            throw err
        }
    }

    async getTeams() {
        const req = new Nothing();
        let data = null;
        try {
            data = await this.client.getTeams(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getTeamsList();
    }

    async createMeetingType(meetingType) {
        const req = new Name()
        req.setName(meetingType)
        try {
            const res = await this.client.createMeetingType(req, {})
            return res.getName()
        } catch (err) {
            console.error(err.message)
            throw err
        }
    }

    async getMeetingTypes() {
        const req = new Nothing();
        let data = null;
        try {
            data = await this.client.getMeetingTypes(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getTypesList()
    }

    async createPresenter(name, messengerNickName, teamId) {
        const req = new AddPresenterRequest()
        req.setName(name)
        req.setMessengerNickName(messengerNickName)
        req.setTeamId(teamId)
        try {
            return await this.client.createPresenter(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
    }

    async deletePresenter(presenterId) {
        const req = new RemovePresenterRequest()
        req.setPresenterId(presenterId)
        let result = null;
        try {
            result = await this.client.removePresenter(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return result.getStatus()
    }

    async getPresenters(teamId) {
        const req = new TeamIDRequest();
        req.setTeamId(teamId);
        let data = null;
        try {
            data = await this.client.getPresenters(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getPresentersList();
    }

    async createMessenger(messengerName) {
        const req = new Name()
        req.setName(messengerName)
        try {
            const res = await this.client.createMessenger(req, {})
            return res.getName()
        } catch (err) {
            console.error(err.message)
            throw err
        }
    }

    async getMessengers() {
        const req = new Nothing();
        let data = null;
        try {
            data = await this.client.getMessengers(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getMessengersList()
    }

    async updateMeetingTypesForMember(memberId, meetingTypes) {
        const req = new UpdateMeetingTypesRequest();
        req.setPresenterId(memberId);
        req.setMeetingTypesList(meetingTypes);
        let data = null;
        try {
            data = await this.client.updateMeetingTypesForPresenter(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getSuccess()
    }

    async changePresenterField(presenterId, fieldName, value) {
        const req = new ChangePresenterFieldRequest();
        req.setPresenterId(presenterId);
        req.setField(fieldName);
        req.setValue(value);
        let data = null;
        try {
            data = await this.client.changePresenterField(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getSuccess()
    }

    async changeTeamField(teamId, fieldName, value) {
        const req = new ChangeTeamFieldRequest();
        req.setTeamId(teamId);
        req.setField(fieldName);
        req.setValue(value);
        let data = null;
        try {
            data = await this.client.changeTeamField(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getSuccess()
    }

    async getCurrentSprintName() {
        const req = new Nothing();
        let sprintName = null;
        try {
            sprintName = await this.client.getCurrentSprint(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return sprintName.getSprint()
    }

    async getRotationDataForTeam(teamId) {
        const req = new TeamIDRequest();
        req.setTeamId(teamId);
        let data = null;
        try {
            data = await this.client.getRotationData(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getRotationsList()
    }

    async rotateTeamPresenters(teamId) {
        const req = new TeamIDRequest();
        req.setTeamId(teamId);
        let data = null;
        try {
            data = await this.client.rotateTeamPresenters(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getRotationsList()
    }

    async rotateTeamPresentersForMeetingType(teamId, meetingTypeId) {
        const req = new TeamIDAndMeetingTypeRequest();
        req.setTeamId(teamId);
        req.setMeetingTypeId(meetingTypeId);
        let data = null;
        try {
            data = await this.client.rotateTeamPresentersForMeetingType(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return data.getRotationsList()
    }

    async toggleOnVacation(presenterId) {
        const req = new ToggleOnVacationStateRequest();
        req.setPresenterId(presenterId);
        let status = false;
        try {
            status = await this.client.toggleOnVacationState(req, {})
        } catch (err) {
            console.error(err.message)
            throw err
        }
        return status
    }

}