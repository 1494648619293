/**
 * @fileoverview gRPC-Web generated client stub for presenter_rotator
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: presenter-rotator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.presenter_rotator = require('./presenter-rotator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.presenter_rotator.PresenterRotatorServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.Name,
 *   !proto.presenter_rotator.Team>}
 */
const methodDescriptor_PresenterRotatorService_CreateTeam = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/CreateTeam',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.Name,
  proto.presenter_rotator.Team,
  /**
   * @param {!proto.presenter_rotator.Name} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.Team.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.Name} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.Team)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.Team>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.createTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/CreateTeam',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_CreateTeam,
      callback);
};


/**
 * @param {!proto.presenter_rotator.Name} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.Team>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.createTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/CreateTeam',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_CreateTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.TeamID,
 *   !proto.presenter_rotator.Team>}
 */
const methodDescriptor_PresenterRotatorService_GetTeam = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetTeam',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.TeamID,
  proto.presenter_rotator.Team,
  /**
   * @param {!proto.presenter_rotator.TeamID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.Team.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.TeamID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.Team)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.Team>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetTeam',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetTeam,
      callback);
};


/**
 * @param {!proto.presenter_rotator.TeamID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.Team>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetTeam',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.Nothing,
 *   !proto.presenter_rotator.Teams>}
 */
const methodDescriptor_PresenterRotatorService_GetTeams = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetTeams',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.Nothing,
  proto.presenter_rotator.Teams,
  /**
   * @param {!proto.presenter_rotator.Nothing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.Teams.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.Teams)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.Teams>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getTeams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetTeams',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetTeams,
      callback);
};


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.Teams>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getTeams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetTeams',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetTeams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.ChangeTeamFieldRequest,
 *   !proto.presenter_rotator.ResponseWithSuccess>}
 */
const methodDescriptor_PresenterRotatorService_ChangeTeamField = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/ChangeTeamField',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.ChangeTeamFieldRequest,
  proto.presenter_rotator.ResponseWithSuccess,
  /**
   * @param {!proto.presenter_rotator.ChangeTeamFieldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.ResponseWithSuccess.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.ChangeTeamFieldRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.ResponseWithSuccess)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.ResponseWithSuccess>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.changeTeamField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/ChangeTeamField',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_ChangeTeamField,
      callback);
};


/**
 * @param {!proto.presenter_rotator.ChangeTeamFieldRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.ResponseWithSuccess>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.changeTeamField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/ChangeTeamField',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_ChangeTeamField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.Name,
 *   !proto.presenter_rotator.MeetingType>}
 */
const methodDescriptor_PresenterRotatorService_CreateMeetingType = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/CreateMeetingType',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.Name,
  proto.presenter_rotator.MeetingType,
  /**
   * @param {!proto.presenter_rotator.Name} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.MeetingType.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.Name} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.MeetingType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.MeetingType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.createMeetingType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/CreateMeetingType',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_CreateMeetingType,
      callback);
};


/**
 * @param {!proto.presenter_rotator.Name} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.MeetingType>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.createMeetingType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/CreateMeetingType',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_CreateMeetingType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.Nothing,
 *   !proto.presenter_rotator.MeetingTypes>}
 */
const methodDescriptor_PresenterRotatorService_GetMeetingTypes = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetMeetingTypes',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.Nothing,
  proto.presenter_rotator.MeetingTypes,
  /**
   * @param {!proto.presenter_rotator.Nothing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.MeetingTypes.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.MeetingTypes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.MeetingTypes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getMeetingTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetMeetingTypes',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetMeetingTypes,
      callback);
};


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.MeetingTypes>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getMeetingTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetMeetingTypes',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetMeetingTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.UpdateMeetingTypesRequest,
 *   !proto.presenter_rotator.UpdateMeetingTypesResponse>}
 */
const methodDescriptor_PresenterRotatorService_UpdateMeetingTypesForPresenter = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/UpdateMeetingTypesForPresenter',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.UpdateMeetingTypesRequest,
  proto.presenter_rotator.UpdateMeetingTypesResponse,
  /**
   * @param {!proto.presenter_rotator.UpdateMeetingTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.UpdateMeetingTypesResponse.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.UpdateMeetingTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.UpdateMeetingTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.UpdateMeetingTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.updateMeetingTypesForPresenter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/UpdateMeetingTypesForPresenter',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_UpdateMeetingTypesForPresenter,
      callback);
};


/**
 * @param {!proto.presenter_rotator.UpdateMeetingTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.UpdateMeetingTypesResponse>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.updateMeetingTypesForPresenter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/UpdateMeetingTypesForPresenter',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_UpdateMeetingTypesForPresenter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.AddPresenterRequest,
 *   !proto.presenter_rotator.AddPresenterResponse>}
 */
const methodDescriptor_PresenterRotatorService_CreatePresenter = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/CreatePresenter',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.AddPresenterRequest,
  proto.presenter_rotator.AddPresenterResponse,
  /**
   * @param {!proto.presenter_rotator.AddPresenterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.AddPresenterResponse.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.AddPresenterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.AddPresenterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.AddPresenterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.createPresenter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/CreatePresenter',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_CreatePresenter,
      callback);
};


/**
 * @param {!proto.presenter_rotator.AddPresenterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.AddPresenterResponse>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.createPresenter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/CreatePresenter',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_CreatePresenter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.TeamIDRequest,
 *   !proto.presenter_rotator.GetPresentersResponse>}
 */
const methodDescriptor_PresenterRotatorService_GetPresenters = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetPresenters',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.TeamIDRequest,
  proto.presenter_rotator.GetPresentersResponse,
  /**
   * @param {!proto.presenter_rotator.TeamIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.GetPresentersResponse.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.TeamIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.GetPresentersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.GetPresentersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getPresenters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetPresenters',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetPresenters,
      callback);
};


/**
 * @param {!proto.presenter_rotator.TeamIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.GetPresentersResponse>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getPresenters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetPresenters',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetPresenters);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.RemovePresenterRequest,
 *   !proto.presenter_rotator.RemovePresenterResponse>}
 */
const methodDescriptor_PresenterRotatorService_RemovePresenter = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/RemovePresenter',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.RemovePresenterRequest,
  proto.presenter_rotator.RemovePresenterResponse,
  /**
   * @param {!proto.presenter_rotator.RemovePresenterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.RemovePresenterResponse.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.RemovePresenterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.RemovePresenterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.RemovePresenterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.removePresenter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/RemovePresenter',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_RemovePresenter,
      callback);
};


/**
 * @param {!proto.presenter_rotator.RemovePresenterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.RemovePresenterResponse>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.removePresenter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/RemovePresenter',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_RemovePresenter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.ChangePresenterFieldRequest,
 *   !proto.presenter_rotator.ResponseWithSuccess>}
 */
const methodDescriptor_PresenterRotatorService_ChangePresenterField = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/ChangePresenterField',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.ChangePresenterFieldRequest,
  proto.presenter_rotator.ResponseWithSuccess,
  /**
   * @param {!proto.presenter_rotator.ChangePresenterFieldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.ResponseWithSuccess.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.ChangePresenterFieldRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.ResponseWithSuccess)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.ResponseWithSuccess>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.changePresenterField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/ChangePresenterField',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_ChangePresenterField,
      callback);
};


/**
 * @param {!proto.presenter_rotator.ChangePresenterFieldRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.ResponseWithSuccess>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.changePresenterField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/ChangePresenterField',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_ChangePresenterField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.ToggleOnVacationStateRequest,
 *   !proto.presenter_rotator.ResponseWithSuccess>}
 */
const methodDescriptor_PresenterRotatorService_ToggleOnVacationState = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/ToggleOnVacationState',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.ToggleOnVacationStateRequest,
  proto.presenter_rotator.ResponseWithSuccess,
  /**
   * @param {!proto.presenter_rotator.ToggleOnVacationStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.ResponseWithSuccess.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.ToggleOnVacationStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.ResponseWithSuccess)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.ResponseWithSuccess>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.toggleOnVacationState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/ToggleOnVacationState',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_ToggleOnVacationState,
      callback);
};


/**
 * @param {!proto.presenter_rotator.ToggleOnVacationStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.ResponseWithSuccess>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.toggleOnVacationState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/ToggleOnVacationState',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_ToggleOnVacationState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.Name,
 *   !proto.presenter_rotator.Messenger>}
 */
const methodDescriptor_PresenterRotatorService_CreateMessenger = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/CreateMessenger',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.Name,
  proto.presenter_rotator.Messenger,
  /**
   * @param {!proto.presenter_rotator.Name} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.Messenger.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.Name} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.Messenger)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.Messenger>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.createMessenger =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/CreateMessenger',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_CreateMessenger,
      callback);
};


/**
 * @param {!proto.presenter_rotator.Name} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.Messenger>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.createMessenger =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/CreateMessenger',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_CreateMessenger);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.Nothing,
 *   !proto.presenter_rotator.Messengers>}
 */
const methodDescriptor_PresenterRotatorService_GetMessengers = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetMessengers',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.Nothing,
  proto.presenter_rotator.Messengers,
  /**
   * @param {!proto.presenter_rotator.Nothing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.Messengers.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.Messengers)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.Messengers>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getMessengers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetMessengers',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetMessengers,
      callback);
};


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.Messengers>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getMessengers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetMessengers',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetMessengers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.TeamIDRequest,
 *   !proto.presenter_rotator.Rotations>}
 */
const methodDescriptor_PresenterRotatorService_GetRotationData = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetRotationData',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.TeamIDRequest,
  proto.presenter_rotator.Rotations,
  /**
   * @param {!proto.presenter_rotator.TeamIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.Rotations.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.TeamIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.Rotations)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.Rotations>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getRotationData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetRotationData',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetRotationData,
      callback);
};


/**
 * @param {!proto.presenter_rotator.TeamIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.Rotations>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getRotationData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetRotationData',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetRotationData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.TeamIDRequest,
 *   !proto.presenter_rotator.Rotations>}
 */
const methodDescriptor_PresenterRotatorService_RotateTeamPresenters = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/RotateTeamPresenters',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.TeamIDRequest,
  proto.presenter_rotator.Rotations,
  /**
   * @param {!proto.presenter_rotator.TeamIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.Rotations.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.TeamIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.Rotations)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.Rotations>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.rotateTeamPresenters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/RotateTeamPresenters',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_RotateTeamPresenters,
      callback);
};


/**
 * @param {!proto.presenter_rotator.TeamIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.Rotations>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.rotateTeamPresenters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/RotateTeamPresenters',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_RotateTeamPresenters);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.TeamIDAndMeetingTypeRequest,
 *   !proto.presenter_rotator.Rotations>}
 */
const methodDescriptor_PresenterRotatorService_RotateTeamPresentersForMeetingType = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/RotateTeamPresentersForMeetingType',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.TeamIDAndMeetingTypeRequest,
  proto.presenter_rotator.Rotations,
  /**
   * @param {!proto.presenter_rotator.TeamIDAndMeetingTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.Rotations.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.TeamIDAndMeetingTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.Rotations)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.Rotations>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.rotateTeamPresentersForMeetingType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/RotateTeamPresentersForMeetingType',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_RotateTeamPresentersForMeetingType,
      callback);
};


/**
 * @param {!proto.presenter_rotator.TeamIDAndMeetingTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.Rotations>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.rotateTeamPresentersForMeetingType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/RotateTeamPresentersForMeetingType',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_RotateTeamPresentersForMeetingType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.Nothing,
 *   !proto.presenter_rotator.SprintResponse>}
 */
const methodDescriptor_PresenterRotatorService_GetCurrentSprint = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetCurrentSprint',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.Nothing,
  proto.presenter_rotator.SprintResponse,
  /**
   * @param {!proto.presenter_rotator.Nothing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.SprintResponse.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.SprintResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.SprintResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getCurrentSprint =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetCurrentSprint',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetCurrentSprint,
      callback);
};


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.SprintResponse>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getCurrentSprint =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetCurrentSprint',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetCurrentSprint);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.Nothing,
 *   !proto.presenter_rotator.AuthenticationLink>}
 */
const methodDescriptor_PresenterRotatorService_GetAuthenticationLink = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetAuthenticationLink',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.Nothing,
  proto.presenter_rotator.AuthenticationLink,
  /**
   * @param {!proto.presenter_rotator.Nothing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.AuthenticationLink.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.AuthenticationLink)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.AuthenticationLink>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getAuthenticationLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetAuthenticationLink',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetAuthenticationLink,
      callback);
};


/**
 * @param {!proto.presenter_rotator.Nothing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.AuthenticationLink>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getAuthenticationLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetAuthenticationLink',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetAuthenticationLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.GetTokenInfoRequest,
 *   !proto.presenter_rotator.AuthenticationData>}
 */
const methodDescriptor_PresenterRotatorService_GetTokenInfo = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/GetTokenInfo',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.GetTokenInfoRequest,
  proto.presenter_rotator.AuthenticationData,
  /**
   * @param {!proto.presenter_rotator.GetTokenInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.AuthenticationData.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.GetTokenInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.AuthenticationData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.AuthenticationData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.getTokenInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetTokenInfo',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetTokenInfo,
      callback);
};


/**
 * @param {!proto.presenter_rotator.GetTokenInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.AuthenticationData>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.getTokenInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/GetTokenInfo',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_GetTokenInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.presenter_rotator.AuthenticationData,
 *   !proto.presenter_rotator.ResponseWithSuccess>}
 */
const methodDescriptor_PresenterRotatorService_Authenticate = new grpc.web.MethodDescriptor(
  '/presenter_rotator.PresenterRotatorService/Authenticate',
  grpc.web.MethodType.UNARY,
  proto.presenter_rotator.AuthenticationData,
  proto.presenter_rotator.ResponseWithSuccess,
  /**
   * @param {!proto.presenter_rotator.AuthenticationData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.presenter_rotator.ResponseWithSuccess.deserializeBinary
);


/**
 * @param {!proto.presenter_rotator.AuthenticationData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.presenter_rotator.ResponseWithSuccess)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.presenter_rotator.ResponseWithSuccess>|undefined}
 *     The XHR Node Readable Stream
 */
proto.presenter_rotator.PresenterRotatorServiceClient.prototype.authenticate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/Authenticate',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_Authenticate,
      callback);
};


/**
 * @param {!proto.presenter_rotator.AuthenticationData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.presenter_rotator.ResponseWithSuccess>}
 *     Promise that resolves to the response
 */
proto.presenter_rotator.PresenterRotatorServicePromiseClient.prototype.authenticate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/presenter_rotator.PresenterRotatorService/Authenticate',
      request,
      metadata || {},
      methodDescriptor_PresenterRotatorService_Authenticate);
};


module.exports = proto.presenter_rotator;

